<template lang="pug">
.login
  b-container
    b-row.no-gutters
      .inner
        b-row
          b-col(cols='7')
            b-img(src='/images/login_splash.png' fluid)
          b-col(cols='5').form-container
            h3.fancy Admin Panel
            b-row
              b-col(cols=10 offset-md="1")
                b-form(@submit.prevent='submit')
                  b-form-group
                    b-input-group
                      template(v-slot:prepend)
                        b-input-group-text
                          b-icon-envelope-fill
                      b-input(type="email" placeholder="Enter email" v-model='form.email' autocomplete="username")
                  b-form-group
                    b-input-group
                      template(v-slot:prepend)
                        b-input-group-text
                          b-icon-lock-fill
                      b-input(type='password' autocomplete="current-password" v-model='form.password' placeholder='Enter Password')
                    b-form-invalid-feedback(:state='!loginFailed') Bad Login / Password.
                  b-button(type="submit" variant="primary" :disabled='submitting' block)
                    b-spinner(small v-if='submitting').mr-2
                    span Login

</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import LoginGQL from '@/graphql/mutations/login.graphql'
import Client from '@/graphql/client'
import { LoginMutation, LoginMutationVariables } from '@/generated/graphql'
@Component({ components: {} })
export default class Login extends Vue {
  submitting = false
  form = {
    email: '',
    password: ''
  }

  loginFailed = false

  async submit () {
    this.submitting = true
    this.loginFailed = false
    try {
      const response = await Client.query<
        LoginMutation,
        LoginMutationVariables
      >(LoginGQL, this.form)
      if (response.login) {
        window.sessionStorage.setItem('adminKey', response.login)
        Client.setKey(response.login)
        this.$currentUser.setJWT(response.login)
        this.$router.push({ name: 'dashboard' })
      }
    } catch (error) {
      this.loginFailed = true
      console.log(error)
    }
    this.submitting = false
  }
}
</script>

<style lang="scss" scoped>
.login {
  height: 100vh;
  display: flex;
  background-color: #1d1d1d;
  .container {
    align-self: center;
    .inner {
      filter: drop-shadow(0 0 0.75rem crimson);
      background-color: #fff;
      border-radius: 10px;
      img {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }
      .form-container {
        padding: 30px 20px 0px 0;
        h3 {
          margin-bottom: 60px;
        }
      }
    }
  }
}
</style>
