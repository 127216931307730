<template lang="pug">
.rooms
  .breadcrumbs
    h2 Tax Reporting
  .table
    b-card.adminCard
      b-button(:to="{name:'taxReportingNew'}" v-if='month').mb-2 Back to monthly view
      b-button(@click='downloadToCSV').mb-2 Download CSV
      b-table(striped hover fixed :fields='fields' :items="taxes" :busy="loading")
        template(#cell(month)="data")
          router-link(v-if='!month' :to="{name: 'taxReportingNew', params: {month: data.item.month}}") {{ dateFormat(data.item.month) }}
          span(v-else) {{ dateFormat(data.item.month) }}
        template(#cell(room)="data")
          span {{ data.item.room / 100 | toCurrency }}
        template(#cell(gratuity)="data")
          span {{ data.item.gratuity / 100 | toCurrency }}
        template(#cell(bookingFee)="data")
          span {{ data.item.bookingFee / 100 | toCurrency }}
        template(#cell(food)="data")
          span {{ data.item.food / 100 | toCurrency }}
        template(#cell(goodies)="data")
          span {{ data.item.goodies / 100 | toCurrency }}
        template(#cell(alcohol)="data")
          span {{ data.item.alcohol / 100 | toCurrency }}
        template(#cell(nonalcohol)="data")
          span {{ data.item.nonalcohol / 100 | toCurrency }}
        template(#cell(tax)="data")
          span {{ data.item.tax / 100 | toCurrency }}
        template(#cell(refunds)="data")
          span {{ data.item.refunds / 100 | toCurrency }}
        template(#cell(giftcards)="data")
          span {{ data.item.giftcards / 100 | toCurrency }}
        template(#cell(total)="data")
          span {{ total(data.item) | toCurrency }}
        template(v-slot:table-busy)
          .text-center.text-danger.my-2
            b-spinner.align-middle
              strong Loading...
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { generateCsv, mkConfig, download } from 'export-to-csv'
import {
  TaxesV2Query
} from '@/generated/graphql'
import { sdk } from '@/graphql/client'
import { format, parse } from 'date-fns'
@Component({ components: {} })
export default class Admins extends Vue {
  @Prop() month!: string

  taxes: TaxesV2Query['taxesV2'] = []
  fields = ['month',
    { key: 'room', label: 'Room' },
    { key: 'food', label: 'Food' },
    { key: 'goodies', label: 'Goodies' },
    { key: 'alcohol', label: 'Alcohol' },
    { key: 'nonalcohol', label: 'Non Alcohol' },
    { key: 'gratuity', label: 'Tips' },
    { key: 'bookingFee', label: 'Fees' },
    { key: 'tax', label: 'Tax' },
    { key: 'refunds', label: 'Refund' },
    { key: 'giftcards', label: 'giftcards' },
    { key: 'total', label: 'total' }
  ]

  loading = false
  mounted () {
    this.loadTaxes()
  }

  total (item: NonNullable<TaxesV2Query['taxesV2'][0]>) {
    return (item.room + item.food + item.goodies + item.alcohol + item.nonalcohol + item.gratuity + item.bookingFee + item.tax) / 100
  }

  dateFormat (string) {
    if (this.month) {
      return format(parse(string, 'yyyy-MM-dd', new Date()), 'dd MMM yyyy')
    } else {
      return format(parse(string, 'yyyy-MM-dd', new Date()), 'MMM yyyy')
    }
  }

  async loadTaxes () {
    this.loading = true
    const taxes = await sdk.TaxesV2({ month: this.month })
    this.taxes = taxes.taxesV2
    this.loading = false
  }

  @Watch('month')
  onMonthChange () {
    this.loadTaxes()
  }

  downloadToCSV () {
    const csvConfig = mkConfig({ useKeysAsHeaders: true })
    const rows = this.taxes.map((tax) => {
      return {
        month: this.dateFormat(tax!.month),
        room: tax!.room / 100,
        food: tax!.food / 100,
        goodies: tax!.goodies / 100,
        alcohol: tax!.alcohol / 100,
        nonalcohol: tax!.nonalcohol / 100,
        gratuity: tax!.gratuity / 100,
        bookingFee: tax!.bookingFee / 100,
        tax: tax!.tax / 100,
        refunds: tax!.refunds / 100,
        giftcards: tax!.giftcards / 100,
        total: this.total(tax!)
      }
    })
    const csv = generateCsv(csvConfig)(rows)
    download(csvConfig)(csv)
  }
}
</script>

<style lang="scss" scoped>
.breadcrumbs {
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #dee2e6;
  margin-bottom: 2rem;
}
.actions {
  margin-bottom: 20px;
  text-align: right;
}
.link {
  cursor: pointer;
  color: #007bff;
  &:hover {
    color: #0056b3;
  }
}

.avatar {
  max-width: 100px;
}
</style>
