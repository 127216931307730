<template lang="pug">
div
  b-navbar(type="dark" variant="dark")
    b-navbar-nav
      b-nav-item(:to='{name: "Home"}')
        b-icon-arrow-left
    b-navbar-brand.mx-auto
      .checkout-title
        b-icon-bag-check.mr-2
        span Manage Your Reservations
  div(v-if='reservation')
    b-container
      b-row
        b-col(md='6')
          b-card.mt-3
            b-card-body
              b-card-title Information about your booking
              b-card-text
                .details
                  b-icon-clock.mr-2
                  span(v-if='refunded') Refunded
                  span(v-else) {{ date }}
                .details
                  b-icon-map.mr-2
                  span 2324 Central Ave, St. Petersburg, FL 33712
              .d-flex.flex-column
                .flex-grow-1.mb-2
                  b-button(size='sm' block :href='linkToInvoice') Invoice
                .flex-grow-1.mb-2
                  b-button(size='sm' :disabled='!cancelable' :to="{name: 'manageRefund', id: this.id, query: { lastMinute: lastMinute }}" block) Cancel / Modify
              b-row.mt-2
                b-col
                  b-alert(variant='warning' :show='!cancelable') You can no longer reschedule or refund this reservation. Reservations can only be modified up to 48 hours prior to the booking.
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import reservationGQL from '@/graphql/queries/reservation.graphql'
import client from '@/graphql/client'
import { ReservationQuery, ReservationQueryVariables, ReservationStatus } from '@/generated/graphql'
import { Maybe } from 'graphql/jsutils/Maybe'
import { format, isAfter, addHours, parseISO } from 'date-fns'

@Component({ components: { } })
export default class Cancel extends Vue {
  @Prop() id!: string
  loaded = false
  canceling = false
  reservation: Maybe<ReservationQuery['ownReservation']> = null
  async mounted () {
    this.reservation = (await client.query<ReservationQuery, ReservationQueryVariables>(reservationGQL, { reference: this.id })).ownReservation
    this.loaded = true
  }

  get dateObject () {
    if (!this.reservation) return new Date()
    const firstSlot = this.reservation.reservationTimeSlots.sort((a, b) => {
      return Number(a.startTime.replace(':', '')) - Number(b.startTime.replace(':', ''))
    })[0]
    const dateWithTime = `${firstSlot.date.split('T')[0]}T${firstSlot.startTime}-0400`
    return parseISO(dateWithTime)
  }

  changeURLPath (url: string, newPath: string) {
    const urlObj = new URL(url)
    urlObj.pathname = newPath
    return urlObj.href
  }

  get refunded () {
    return this.loaded && this.reservation && this.reservation.status === ReservationStatus.Refunded
  }

  get date () {
    return format(this.dateObject, 'PPPPpp')
  }

  get notFound () {
    return this.loaded && !this.reservation
  }

  get linkToInvoice () {
    return this.changeURLPath(String(process.env.VUE_APP_API_URL), `/invoice/${this.id}`)
  }

  get paid () {
    return !this.notFound && this.reservation!.status === ReservationStatus.Paid
  }

  get lastMinute () {
    return this.$route.query.lastMinute === 'true'
  }

  get cancelable () {
    return this.paid && (this.lastMinute || isAfter(this.dateObject, addHours(new Date(), 48)))
  }

  get late () {
    return this.paid && !this.cancelable
  }
}
</script>

<style lang="scss" scoped>
.details {
  margin-bottom: 15px;
}
</style>
